<template>
  <div class="page-container">
    <div>
      <section class="page-header">
        <h2 class="font-weight-bold">
          Seja bem-vindo(a) ao novo Portal do Cooperado!
        </h2>
      </section>

      <div class="separator-line"></div>
      <section class="sub-header">
        <p class="color-green-2 p3">
          Pensando em melhorar a sua experiência, preparamos um novo portal com
          serviços exclusivos. Confira as funcionalidades que você pode
          explorar:
        </p>
        <p class="color-green-2 p3">
          Solicitar atualizações cadastrais | Verificar sua analítica | Acesso
          ao consultório virtual | Realizar solicitações de documentos
        </p>
        <p class="color-green-2 p3">
          <b>E vem muito mais por aí!</b>
        </p>
      </section>
    </div>
    <section>
      <div class="card-container">
        <CustomInfoCard
          class="deck deck-margin"
          v-for="card in getCards"
          :key="card.id"
          :onCardClick="() => onClickCard(card)"
          :headerIcon="card.headerIcon"
          :title="card.header"
          :description="card.description"
          :icon="card.icon"
          :featureName="card.featureName"
        />
      </div>
    </section>
    <section class="page-header">
      <p class="p3">
        Para entrar em contato com nossa equipe, utilize o WhatsApp no número
        <u class="text-primary pointer" @click="onClickWhatzapp">
          (48) 9 9119.1130
        </u>
        , disponível de segunda a sexta-feira, das 8h às 18h.
      </p>
    </section>
  </div>
</template>

<script>
import CustomInfoCard from "../../../components/custom-info-card/CustomInfoCard.vue";
import RoutesPaths from "../../../router/router-structure/routes-paths";
import { isMobile } from "../../../utils/mobile/mobile";

const linkSurgicalConsentForm =
  "https://www.unimed.coop.br/site/web/florianopolis/formul%C3%A1rios-para-autoriza%C3%A7%C3%A3o";
const linkWhatzapp =
  "https://web.whatsapp.com/send?phone=5548991191130&text=Portal+Cooperado";
const linkConnection =
  "https://sites.google.com/unimedflorianopolis.com.br/conexao-cooperado/pagina-inicial";

export default {
  components: {
    CustomInfoCard,
  },
  computed: {
    getCards: () => {
      let cards = [
        {
          id: 0,
          icon: "analitycs-card.svg",
          header: "Analítica",
          description:
            "Tenha acesso a analítica financeira E gerencie seus ganhos",
          route: RoutesPaths.financial.analytics(),
          showMobile: true,
          featureName: "HOME_CARD_ANALYTICS",
        },
        {
          id: 1,
          icon: "MDI-lightbulb-on-outline.svg",
          header: "Treinamentos",
          description:
            "Aqui oferecemos uma ampla variedade de cursos e treinamentos abordando temas diversos",
          route: RoutesPaths.trainingPage.rootName(),
          showMobile: false,
          featureName: "HOME_CARD_TRAINING",
        },
        {
          id: 2,
          icon: "panel-economic.svg",
          header: "Painel Econômico",
          description:
            "Acesse os principais resultados financeiros e administrativos da cooperativa",
          route: RoutesPaths.institutionalPage.panelEconomic(),
          showMobile: true,
          featureName: "HOME_CARD_PAINEL_ECONOMIC",
        },
        {
          id: 3,
          icon: "document-card-outline.svg",
          header: "Documentos e atas UGF",
          description: "Tenha acesso a atas, resultados antigos, etc",
          route: RoutesPaths.documentsAtasPage.documentsAtas(),
          showMobile: true,
          featureName: "HOME_CARD_DOCUMENT_CARD",
        },
        {
          id: 4,
          icon: "surgical-consent-form.svg",
          header: "Formulários e Termo de Consentimento Cirúrgico",
          description:
            "Acesse os formulários necessários para solicitar procedimento aos seus pacientes.",
          link: linkSurgicalConsentForm,
          showMobile: false,
          featureName: "HOME_CARD_SURGICAL_CONSENT",
        },
        {
          id: 5,
          icon: "income-history.svg",
          header: "Histórico de Notas Fiscais",
          description: "Acesse o histórico de Notas Fiscais",
          route: RoutesPaths.financial.incomeHistory(),
          showMobile: true,
          featureName: "HOME_CARD_INCOME_HISTORY",
        },
        {
          id: 6,
          icon: "bulletin-board.svg",
          header: "Conexão Cooperado",
          description:
            "Acesse a página de informações exclusiva para o médico cooperado e fique por dentro das notícias sobre a sua Cooperativa",
          link: linkConnection,
          showMobile: false,
          featureName: "HOME_CARD_BULLETIN_BOARD",
        },
        {
          id: 7,
          icon: "email-send-outline.svg",
          header: "Outras Solicitações",
          description:
            "Aqui você pode protocolar suas solicitações para a área do cooperado.",
          route: RoutesPaths.benefit.otherSolicitation(),
          showMobile: true,
          featureName: "HOME_CARD_EMAIL_OUTLINE",
        },
      ];
      if (isMobile()) {
        return cards.filter((c) => c.showMobile);
      }
      return cards;
    },
  },
  methods: {
    isMobile: isMobile,
    onClickCard({ route, link }) {
      if (link) {
        window.open(link, "_blank");
      } else if (route) this.$router.push(route);
    },
    onClickWhatzapp() {
      window.open(linkWhatzapp, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.deck-margin {
  margin: 2rem;
}

.page-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.page-header {
  text-align: center;
}

.sub-header {
  max-width: 83rem;
  margin: 0 auto;
  margin-top: 1.5rem;
  text-align: center;
}

.card-container {
  max-width: 100rem;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @include media-breakpoint-up(xs) {
    justify-content: center;
  }

  @include media-breakpoint-up(md) {
    justify-content: center;
  }
}

.pointer {
  cursor: pointer;
}
</style>
