<template>
  <CustomAccordionButton class="component-training" :collapseId="id">
    <template slot="header"
      ><div class="title T16 color-primary">
        {{ $props.title }}
      </div></template
    >
    <template slot="content">
      <div
        v-for="(item, index) in $props.steps"
        :key="id + index"
        :class="getClassToPending(item, $props.steps[index - 1])"
      >
        <hr />
        <div
          class=" align-items-center step-title T16"
          @click="() => onClick(item)"
        >
          <div>
            <img
              class="custom-img"
              :src="getPathUrl(item, $props.steps[index - 1])"
            />
          </div>
          <div class="title align-items-center">
            {{ item.title }}
          </div>
          <div class="ml-auto icon-download-pdf" v-if="item.type == 'PDF'">
            <img
              class="custom-img"
              @click="onDownload(item)"
              :src="resolve_path_url('download-pencil-outline.svg')"
            />
          </div>
        </div>
      </div>
    </template>
  </CustomAccordionButton>
</template>

<script>
import CustomAccordionButton from "../../../components/custom-accordion/CustomAccordionButton.vue";
import { resolve_path_url } from "../../../utils/image/image-utils";

export default {
  components: {
    CustomAccordionButton,
  },
  props: ["title", "status", "steps", "onStepClick", "currentStep", "id"],
  data() {
    return {
      icons: {
        VIDEO: {
          enabled: "MDI-motion-play.svg",
          disabled: "MDI-motion-play-disabled.svg",
          pendingDisabled: false,
        },
        PDF: {
          enabled: "MDI-pdf.svg",
          disabled: "MDI-pdf-disabled.svg",
          pendingDisabled: true,
        },
        FORM: {
          enabled: "MDI-message-question.svg",
          disabled: "MDI-message-question-disabled.svg",
          pendingDisabled: true,
        },
        CERTIFICATE: {
          enabled: "MDI-certificate.svg",
          disabled: "MDI-certificate-disabled.svg",
          pendingDisabled: true,
        },
      },
    };
  },
  methods: {
    getClassToPending(item, beforeStep) {
      let classCss = "";
      if (this.$props.currentStep?.id == item.id) {
        classCss += " effect ";
      }

      if (item.order == 0 || beforeStep?.status == "FINISHED") {
        classCss += " cursor-point ";
      }

      return classCss;
    },
    getPathUrl(item, beforeStep) {
      return item.order == 0 || beforeStep?.status == "FINISHED"
        ? resolve_path_url(this.icons[item.type].enabled)
        : resolve_path_url(this.icons[item.type].disabled);
    },
    onClick(step) {
      this.$props.onStepClick(step, this.$props.steps);
    },
    onDownload(item) {
      if (item?.status != "FINISHED") return;
      const aElement = document.createElement("a");

      if (this.$store.getters.isApp) {
        aElement.href = item.link.replace("https", "http");
      } else {
        aElement.href = item.link;
      }

      aElement.setAttribute("target", "_blank");
      aElement.click();
    },
    resolve_path_url: resolve_path_url,
  },
};
</script>
<style lang="scss" scoped>
.component-training {
  margin-bottom: 1em;
}
.cursor-point {
  cursor: pointer;
}
.title {
  padding-left: 1rem;
  font-size: 1.6rem;
}
.custom-step-menu {
  border-top-width: 0.5px;
  border-top-style: solid;
  border-top-color: #cdd2d7;

  padding-left: 1rem;
  display: grid;
  width: 100%;
  height: 4.4rem;
  padding-right: 0;
  grid-template-columns: 35px auto;
}
.cursor-point {
  cursor: pointer;
}
.effect {
  border-right-width: 3px;
  border-right-style: solid;
  border-right-color: #b1d34b !important;
}
.step-title {
  display: flex;
  flex-flow: row wrap;
  margin-left: 30px;
  padding-top: 12px;
  padding-bottom: 12px;
}
.custom-img {
  height: 2.2rem;
  width: 2.2rem;
}
hr {
  border-color: #aaaaaa36;
  box-sizing: border-box;
  width: 100%;
  margin: 0px 0 0 0;
}
.selected {
  border-right-width: 3px;
  border-right-style: solid;
  border-right-color: #b1d34b !important;
}

.icon-download-pdf {
  margin-right: 6px;
}
</style>
