<template>
  <div class="login-container">
    <div
      :class="isMobile() ? 'login-mobile-container' : ''"
      class="login-card-container"
    >
      <div class="login-wecolme">
        <h2>Bem-vindo(a)!</h2>
        <div class="separator-line"></div>

        <p class="p2">
          A Unimed Grande Florianópolis preparou um espaço exclusivo para você
          médico cooperado.
        </p>
      </div>
      <div class="mt-5">
        <b-form @submit="this.onSubmit">
          <b-card class="login-card card-border--1">
            <b-card-body>
              <div>
                <b-form-group label="CRM" label-for="crmLogin">
                  <b-form-input
                    placeHolder="Informe seu CRM"
                    id="crmLogin"
                    required
                    v-model="user.login"
                  />
                </b-form-group>
                <b-form-group label="Senha" label-for="passwordLogin">
                  <b-form-input
                    placeHolder="Informe sua senha"
                    id="passwordLogin"
                    type="password"
                    required
                    v-model="user.password"
                  />
                </b-form-group>
                <!-- <b-link>Esqueci minha senha</b-link> -->
                <div class="mt-4">
                  <b-button type="submit" variant="primary" id="entrar"
                    >Entrar
                    <i
                      class="fas fa-spinner fa-spin icon"
                      v-show="this.loading"
                    ></i
                  ></b-button>
                </div>
                <div
                  class="invalid"
                  v-show="this.msgErroInvalid != null && !this.loading"
                >
                  <i class="fas fa-exclamation-circle invalid"></i>
                  {{ this.msgErroInvalid }}
                </div>
              </div>
            </b-card-body>
          </b-card>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "@vue/composition-api";
import RoutesPaths from "../../../router/router-structure/routes-paths";
import { isMobile } from "../../../utils/mobile/mobile";

export default {
  setup() {
    const user = ref({ login: null, password: null });
    return { user };
  },
  data() {
    return {
      loading: false,
      msgErroInvalid: null,
      acceptedTermUse: false,
    };
  },
  methods: {
    isMobile: isMobile,
    async onSubmit(e) {
      e.preventDefault();
      await this.doLogin();
    },
    async doLogin() {
      try {
        this.loading = true;
        this.setDisabled(true);

        const response = await this.$store.dispatch("requestLogin", this.user);
        await this.$store.dispatch("initializePermissions", response);

        await this.$store.dispatch("verifyUseTerm");
        await this.$store.dispatch("setAdminUser", this.user?.login);

        this.$router.push(RoutesPaths.home.rootName());
        this.loading = false;
        this.setDisabled(false);
      } catch (error) {
        this.loading = false;
        console.error("doLogin", error);
        this.setDisabled(false);

        if (
          error.response &&
          error.response.status == 400 &&
          error.response.data &&
          error.response.data.message == "Bad credentials"
        ) {
          this.msgErroInvalid = "CRM e/ou senha inválido(s)";
        }
      }
    },
    setDisabled(value) {
      document.getElementById("crmLogin").disabled = value;
      document.getElementById("passwordLogin").disabled = value;
      document.getElementById("entrar").disabled = value;
    },
  },
};
</script>

<style lang="scss" scoped>
.login-card-container {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  width: 55rem;
}

.login-card {
  width: 44rem;
  height: 41rem;
  padding: 4rem 5rem;
  @include header5;
}

.login-container {
  display: flex;
  width: 100%;
  height: 100%;
  background-image: url("../../../assets/images/login/login-illustration.svg");
  background-repeat: no-repeat;

  @include media-breakpoint-up(xs) {
    justify-content: center;
    background-position: right bottom;
    background-size: 90% 50%;
  }

  @include media-breakpoint-up(sm) {
    justify-content: center;
    background-position: right bottom;
    background-size: 90% 50%;
  }

  @include media-breakpoint-up(md) {
    justify-content: center;
    background-position: right bottom;
    background-size: 50% 50%;
  }

  @include media-breakpoint-up(lg) {
    justify-content: initial;
    background-position: right top;
    background-size: 60% 100%;
  }

  @include media-breakpoint-up(xl) {
    justify-content: initial;
    background-position: right top;
    background-size: 60% 100%;
  }
}

.login-card-container {
  /* width: 44rem; */
  /* height: 41rem; */
}

.login-wecolme {
  max-width: 43rem;
  text-align: center;
}
.icon {
  font-size: 2rem;
}

.invalid {
  color: #dc3545;
  margin-top: 0.8rem;
}
.login-mobile-container {
  width: 38rem;
}
</style>
