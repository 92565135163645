import store from "../../store";

export default function requestBuilder(
  formData,
  requestType,
  attachments = [],
  typeService,
  adicional,
  changedData
) {
  const { crm, name, codePerson } = store.getters.getUserInformation;
  console.log(store.getters)
  const objectRequest = {
    ...adicional,
    requester: {
      crm: crm,
      name: name,
      codePerson: codePerson,
    },
    typeService: typeService,
    type: "REGISTRATION_UPDATE",
    typeRequestedFlow: requestType,
    typeSendForm: "EMAIL",
    applicationType: "APP",
    metadata: formData,
    changedData: changedData,
  };
  let formRequest = new FormData();
  const blob = new Blob([JSON.stringify(objectRequest)],{
    type: 'application/json'
  });
  formRequest.append("model", blob);
  
  if (attachments.length > 0) { 
    attachments.forEach(file=> formRequest.append("files", file));
  }
  return formRequest
}
