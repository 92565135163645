<template>
  <div class="">
    <p class="p1" v-text="this.juridical.descriptionProduction"></p>
    <div class="d-flex flex-wrap imagem-analytics">
      <CustomLargeCard
        :amountReceivable="juridical.mainData.amountReceivable"
        :discount="juridical.mainData.discount"
        :grossValue="juridical.mainData.grossValue"
        :invoiceValue="juridical.mainData.invoiceValue"
        icon="icon_analitica.svg"
        class="margin-analytics mt-5"
      />

      <div class="d-flex flex-column mt-5">
        <CutomCard
          :amountReceivable="this.juridical.backBeat"
          title="BACKBEAT"
          :isShowLink="true"
          :onDetailClick="onBackbeatDetailClick"
        />
        <CutomCard
          :amountReceivable="juridical.grosses"
          title="GLOSAS"
          :onDetailClick="onGlossesDetailClick"
          :isShowLink="this.$props.isShowLink"
          class="margin"
        />
      </div>
    </div>

    <div class="span my-5"></div>
  </div>
</template>

<script>
import CustomLargeCard from "../modal/CustomLargeCard.vue";
import CutomCard from "../modal/CutomCard.vue";
import RoutesPaths from "../../../../router/router-structure/routes-paths";
import { showBackbeat } from "../../../../services/backbeat/route-page";

export default {
  props: ["juridicalPerson", "year", "month", "isShowLink"],
  data() {
    return {
      juridical: this.$props.juridicalPerson,
    };
  },
  components: {
    CustomLargeCard,
    CutomCard,
  },
  methods: {
    onBackbeatDetailClick() {
      showBackbeat(this.$props.year, this.$props.month);
    },
    onGlossesDetailClick() {
      this.$router.push({
        path: RoutesPaths.financial.reviewGlosses(),
        query: { year: this.$props.year, month: this.$props.month },
        replace: true,
      });
    },
  },
};
</script>

<style scoped>
.margin {
  margin-top: 4.2rem;
}

.span {
  display: flex;
  width: 100%;
  height: 0.15rem;
  border-radius: 20%;
  background-color: var(--light-4);
}

.margin-analytics {
  margin-right: 9rem;
}

.imagem-analytics {
  background-image: url("../../../../assets/images/ilus_analitica.svg");
  background-repeat: no-repeat;
  background-position: 85% 90%;
}
</style>
