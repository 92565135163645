<template>
  <div>
    <SimpleLayout
      viewComponent="quota-statement"
      :cardInfo="this.cardInfo"
      :leftAction="this.leftAction"
    />
  </div>
</template>

<script>
import Vue from "vue";
import SimpleLayout from "../../../layouts/SimpleLayout.vue";
import RoutesPaths from "../../../router/router-structure/routes-paths";

Vue.component("quota-statement", () =>
  import("../components/visualization/QuotaStatementTab.vue")
);

export default {
  components: {
    SimpleLayout,
  },
  data: function() {
    return {
      leftAction: {
        text: "Financeiro",
        onClick: this.leftActionClickHandler,
      },
      cardInfo: {
        icon: "file-document-outline.svg",
        title: "Cota Capital",
        description: "Acesse o seu extrato de cota de forma fácil e rápida.",
      },
    };
  },
  methods: {
    leftActionClickHandler() {
      this.$router.push({
        path: RoutesPaths.financial.rootName(),
        replace: true,
      });
    },
  },
};
</script>
