import { GENESIS_URL } from "../urls/base-urls";
import axios from "../http-client/axios";

export async function getTrainings(status, title, page, size) {
  let query = `?status=${status}&page=${page}&size=${size}`;

  if(title)
    query += `&title=${title}`

  const resp = await axios.get(`${GENESIS_URL}/list-training${query}`);
  return resp.data;
}

export async function getTrainingsReport(idTraining) {  

  const resp = await axios.get(`${GENESIS_URL}/report-training?idTraining=${idTraining}`);
  return resp.data;
}