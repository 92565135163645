import axios from "axios";
import { createToast } from "../../components/toast/toast";
import router from "../../router";
const axiosIntance = axios.create({
  baseURL: "",
});
import store from "../../store";
import { Firebase } from "../firebase/log-firebase";

axiosIntance.interceptors.request.use(
  async function(config) {

    const response = config.url.includes("/oauth/token")
      ? true
      : await store.dispatch("validarLogin");

    if (!response) {
      store.dispatch("logout");
      router.push("/login");
      return;
    }

    config.headers["Authorization"] = `Bearer ${store.getters.getToken}`;
    config.headers["Content-Type"] = "application/json";
    config.headers["Accept"] = "*/*";
    return config;
  },
  function(err) {
    return Promise.reject(err);
  }
);

axiosIntance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response && error.response.status == 401)
      return handleRefreshToken(error);

    if (error.response && error.response.status == 500) {

      var firebase = new Firebase();
      firebase.addErrorLog("Ocorreu um erro inesperado: Ex ->" + JSON.stringify(error.response));
      createToast("Atenção", "Ops, Ocorreu algo inesperado. Tente Novamente.");
    }     
      
    return Promise.reject(error);
  }
);

const handleRefreshToken = async (error) => {
  if (store.getters.getLoading) return;
  if (store.getters.getTokenAdmin) {
    await store.dispatch("cancelLoginInspect");
    return;
  }
  try {
    await store.dispatch("refreshToken");
    error.config.headers["Authorization"] = `Bearer ${store.getters.getToken}`;
    return axiosIntance.request(error.config);
  } catch (error) {
    store.dispatch("logout");
    router.push("/login");
    createToast(
      "Atenção",
      "Seu login expirou! É necessário efetuar o login novamente.",
      "warning"
    );
    return Promise.reject(error);
  }
};

export default axiosIntance;
