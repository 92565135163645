<template>
  <div class="custom-principal">
    <div class="page-container">
      <VisualizationReviewGlossesLayout
        :leftInfo="leftInfo"
        :leftAction="leftAction"
      >
        <template slot="headerContent">
          <div class="body">
            <div class="body-internal">
              <p class="mb-4 text-cabecalho">
                Aqui você pode solicitar o recurso de glosas das três últimas
                competências, fazendo o download do relatório e posteriormente
                enviando a planilha com as suas considerações através do botão
                de upload.
              </p>
              <div
                class="d-flex justify-content-between mb-3 custom-competence"
              >
                <div class="p-2 align-self-center">
                  <p
                    @click="onAccessHistoryGlosses()"
                    class="text-acessar custom-pointer"
                  >
                    <img src="../../../assets/images/MDI-history.svg" />
                    Acessar Histórico de solicitações
                  </p>
                </div>
                <div>
                  <div class="d-flex mb-3 justify-content-end flex-wrap w-100">
                    <div class="p-2 custom-periodo align-self-center">
                      COMPETÊNCIA:
                    </div>
                    <div class="p-1 align-self-center">
                      <b-form-select
                        v-model="selectedOption"
                        :options="competencyYearsOptions"
                        v-on:change="onChangeFormSelect"
                        size="lg"
                        class=" custom-select-head"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="buttons-container">
                <div class="cards">
                  <div
                    @click="onClickDownload()"
                    v-show="reviewGlosses != null"
                    class="cards--secundario cards--secundario--primary hover-internal custom-pointer"
                  >
                    <div class="img-review-glosses">
                      <img src="../../../assets/images/download.svg" />
                    </div>
                    <div class="text-review-glosses">
                      <h5>
                        <span class="font-weight-bold custom-text-title-card"
                          >Download</span
                        >
                      </h5>
                      <p>
                        Clique aqui e baixe o relatório das glosas.
                      </p>
                    </div>
                  </div>

                  <div
                    v-show="reviewGlosses == null"
                    class="cards--secundario cards--secundario--primary"
                  >
                    <div class="img-review-glosses">
                      <img src="../../../assets/images/download-enabled.svg" />
                    </div>
                    <div class="text-review-glosses">
                      <h5>
                        <span
                          class="font-weight-bold custom-text-title-card--enabled"
                          >Download</span
                        >
                      </h5>
                      <p>
                        Clique aqui e baixe o relatório das glosas.
                      </p>
                    </div>
                  </div>

                  <div
                    @click="$refs.fileUpload.click()"
                    class="cards--secundario hover-internal custom-pointer"
                  >
                    <div class="img-review-glosses">
                      <img src="../../../assets/images/upload.svg" />
                    </div>
                    <div class="text-review-glosses">
                      <h5 class="">
                        <span class="font-weight-bold custom-text-title-card"
                          >Upload</span
                        >
                      </h5>
                      <p>
                        Clique aqui e envie sua solicitação para revisão das
                        glosas.
                      </p>
                    </div>
                  </div>
                  <b-button
                    ref="fileUpload"
                    class="my-3 mr-3"
                    variant="outline-primary"
                    v-b-modal:upload-file-modal
                    v-show="false"
                  >
                    Adicionar endereço
                  </b-button>
                </div>
                <div class="review-glosses--img"></div>
              </div>
            </div>
          </div>
        </template>
      </VisualizationReviewGlossesLayout>
    </div>
    <UploadGlosasModal ref="upload-file-modal" @errorUploadFile="showMessageError"/>
    <success-modal
      ref="success-modal"
      description="Seu arquivo foi enviado com sucesso"
      :subdescription="subdescriptionUpload"
      buttonText="Ok, entendi!"
    />
  </div>
</template>

<script>
import UploadGlosasModal from "../components/visualization/UploadGlosasModal.vue";
import VisualizationReviewGlossesLayout from "../components/visualization/VisualizationReviewGlossesLayout.vue";
import {
  getCompetences,
  getReviewGlassesAPI,
} from "../../../services/review-glosses/review-glosses";
import { inject } from "@vue/composition-api";
import SuccessModal from "../../../components/base-modals/SuccessModal.vue";
import { getMonthNumber } from "../../../utils/date/date-utils";
import { ServiceType } from "../../../static-data/ServiceType";
import RoutesPaths from "../../../router/router-structure/routes-paths";
import { anchorBase64Download } from "../../../utils/anchor-base64-download/anchor-base64-download";
import { createToast } from "../../../components/toast/toast";

export default {
  components: {
    VisualizationReviewGlossesLayout,
    SuccessModal,
    UploadGlosasModal,
  },
  computed: {
    yearsOptions: () => {
      let years = [];
      for (var n = 2017; n <= new Date().getFullYear(); n++) {
        years.push(n);
      }
      return years.reverse();
    },
  },
  setup() {
    const globalLoading = inject("globalLoading");
    return { globalLoading };
  },
  data() {
    return {
      competence: null,
      selectedOption: null,
      selectOptions: [],
      competencyYearsOptions: [],
      subdescriptionUpload: "",
      reviewGlosses: null,
      year: new Date().getFullYear(),
      leftAction: {
        text: "Solicitar revisão de glosas",
        onClick: this.leftActionClickHandler,
      },
      leftInfo: "",
      fields: [],
      items: [],
      selectedViewRequestItem: null,
      requestTypeDescription: "",
      selectedAttachments: [],
      files: [],
      competencyOptions: [],
    };
  },
  mounted() {
    this.month = this.$route.query.month;
    this.year = this.$route.query.year;
    if (this.month != undefined || this.month != null) {
      this.competence =
        getMonthNumber(this.$route.query.month) + "/" + this.$route.query.year;

      this.startProcess();
    } else {
      this.startProcess();
    }
  },
  methods: {
    showMessageError(message) {
      createToast( "Informação", message, "warning");
    },
    async checkCompetencePermition() {
      this.competence = this.competencyOptions[0].competence;
      for (let index = 0; index < this.competencyOptions.length; index++) {
        const isMatch = this.competencyOptions[index];
        if (
          isMatch.competence ==
          getMonthNumber(this.month) + "/" + this.year
        ) {
          this.competence = isMatch.competence;
          break;
        }
      }
    },
    async startProcess() {
      await this.getCompetenceYears();
      this.checkCompetencePermition();
      this.onSelectCompetence(this.competence);
      await this.getDetailsReviewGlossesHandler();
    },
    async getDetailsReviewGlossesHandler() {
      this.year = this.getYear();
      this.month = this.getMonth();
      try {
        this.globalLoading = true;
        const crm = this.$store.getters.getUserName;

        this.reviewGlosses = null;
        const response = await getReviewGlassesAPI(
          crm,
          this.month,
          this.year
        );
        
        if(response)
          this.reviewGlosses = response;
        else        
          createToast("Atenção", "Relatório de glosas não encontrado.", "primary");

        this.globalLoading = false;
      } catch (error) {
        this.globalLoading = false;
        console.error("getDetailsReviewGlossesHandler", error);
      }
    },
    leftActionClickHandler() {
      this.$router.go(-1);
    },
    getYear() {
      return this.competence.substr(3, 4);
    },
    getMonth() {
      return this.competence.substr(0, 2);
    },
    onClickDownload() {
      const fileBase64 = this.reviewGlosses.fileBase64;
      const name = this.reviewGlosses.name;

      anchorBase64Download(
        this.$store.getters.isApp,
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        name,
        fileBase64
      );
    },
    async onActionHandler() {
      const modal = this.$refs["upload-file-modal"].$children[0];
      modal.show();
    },
    async getCompetenceYears() {
      try {
        this.globalLoading = true;
        const crm = this.$store.getters.getUserName;
        this.competencyOptions = await getCompetences(crm);
        this.competencyYearsOptions = this.competencyOptions.map((data) => ({
          text: `${data.competence}`,
          value: data,
        }));

        this.globalLoading = false;
      } catch (error) {
        this.globalLoading = false;
        console.error("Error on function getCompetenceYears: ", error);
      }
    },
    onChangeFormSelect(formData) {
      this.competence = formData.competence;
      this.getDetailsReviewGlossesHandler();
    },
    onSelectCompetence(value) {
      if (value == null) {
        this.selectedOption = this.competencyYearsOptions[0].value;
        return;
      }

      let count = 0;
      let auxCount = 0;
      this.competencyYearsOptions.forEach((element) => {
        if (element.value.competence == value) {
          auxCount = count;
        }
        count++;
      });
      this.selectedOption = this.competencyYearsOptions[auxCount].value;
    },
    onAccessHistoryGlosses() {
      this.$router.push({
        path: RoutesPaths.requestHistory.rootName(),
        params: {
          serviceType: ServiceType.ItensGlossados,
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.custom-principal {
  display: flex;
  justify-content: center;
  background-color: var(--background);
  height: 100%;
}

.page-container {
  display: flex;
  flex-direction: column;
  width: 95%;
}

.body {
  display: flex;
  justify-content: center;
  width: 100%;
}

.body-internal {
  display: flex;
  flex-direction: column;
  width: 97%;
}

.custom-competence {
  width: 100%;
}

.custom-pointer {
  cursor: pointer;
}

.review-glosses {
  &--img {
    margin-top: 1rem;

    min-height: 20rem;
    min-width: 32rem;

    background-image: url("../../../assets/images/Ilustra.svg");
    background-repeat: no-repeat;
    background-position: 85% 50%;
    background-size: 34rem 20rem;

    @include media-breakpoint-down(xs) {
      margin: 0 auto;
      margin-top: 1rem;
    }

    @include media-breakpoint-down(sm) {
      margin: 0 auto;
      margin-top: 1rem;
    }
  }

  &--options {
    display: flex;
    flex-direction: row;

    border-radius: 1.5rem;

    margin-top: 7.5rem;
    width: 32rem;
    max-width: 32rem;
    margin-left: 15%;
  }
}

.cards {
  margin: 2rem 0;
  width: 32rem;
  min-width: 32rem;

  &--secundario {
    h5 {
      margin-top: 5%;
    }

    &--primary {
      margin-bottom: 2.5rem;
    }

    display: flex;
    flex-direction: row;

    border-radius: 1.5rem;

    background-color: white;
    width: 100%;
    text-align: left;
    height: 7.5rem;
  }
}

.buttons-container {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;

  @include media-breakpoint-down(xs) {
    flex-direction: column;
    flex-grow: 1;
  }

  @include media-breakpoint-down(sm) {
    flex-direction: column;
    flex-grow: 1;
  }
}

.img-review-glosses {
  img {
    padding-top: 35%;
    padding-left: 12%;
  }
  width: 17%;
  justify-content: center;
}

.text-review-glosses {
  width: 80%;
}

.hover-internal:hover {
  border: 0.1rem solid #0a5f55 !important;
}

.text-cabecalho {
  font-size: multiplier-default-font-size(1); //12px
  color: var(--grey-2);
}

.text-acessar {
  font-size: multiplier-default-font-size(3); //12px
  color: var(--primary);
}

.custom-periodo {
  color: var(--grey-1);
}
.custom-text-title-card {
  color: var(--green-2);
  &--enabled {
    color: var(--grey-2);
  }
}
.input-file {
  display: none;
}
</style>
