import { GENESIS_URL } from "../urls/base-urls";
import axios from "../http-client/axios";

export async function getStatusTrainingRunning() {
  const resp = await axios.get(`${GENESIS_URL}/status-training-running`);
  return resp.data;
}

export async function getStatusTrainingFinished() {
  const resp = await axios.get(`${GENESIS_URL}/status-training-finished`);
  return resp.data;
}

export async function updateStatusTraining(idStep, idTraining, status, name) {
  const resp = await axios.post(`${GENESIS_URL}/update-status-training`, 
    {
      idTraining,
      idStep,
      status, 
      name,
    });
  return resp.data;
}
