<template>
  <div v-if="$props.item && $props.item.type == 'PDF'">
    <div class="container">
      <div class="row">
        <div class="col-sm pdf-display">
          <pdf-viewer
            :key="$props.item.link"
            :getDocumentHandler="getDocumentHandler"
            @onLastPageVisualized="lastPageVisualized"
            @onPdfLoaded="onPdfLoaded"
            :loading="this.loading"
          >
            <template slot="left-button">
              <b-button
                v-show="$props.item && $props.item.lastStep == false"
                class="my-2 left-button-next"
                :disabled="$props.item && $props.item.status != 'FINISHED'"
                @click="() => onClickNextStep()"
                variant="primary"
              >
                Ir para próxima etapa
              </b-button>
            </template>
          </pdf-viewer>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PdfViewer from "../../../components/pdf-viewer/PdfTrainingViewer.vue";
export default {
  props: ["onFinishedStep", "onStartStep", "onNextStep", "item"],
  components: { PdfViewer },
  data() {
    return {
      loading: true,
    };
  },
  methods: {
    async getDocumentHandler() {
      this.loading = true;
      await this.$props.onStartStep(this.$props.item);
      const toReturn = fetch(this.$props.item.link)
        .then((response) => response.blob())
        .then((blob) => {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = function() {
              const base64 = reader.result.replace(/^data:.+;base64,/, "");
              resolve(base64);
            };
            reader.onerror = function(error) {
              this.loading = false;
              reject(error);
            };
            reader.readAsDataURL(blob);
          });
        });
      return toReturn;
    },
    onClickNextStep() {
      this.$props.onFinishedStep(this.$props.item);
      this.$props.onNextStep();
    },
    lastPageVisualized() {
      this.$props.onFinishedStep(this.$props.item);
      this.$props.item.status = "FINISHED";
    },
    onPdfLoaded(){
      this.loading = false;
    }
  },
};
</script>
<style lang="scss" scoped>
.left-button-next {
  position: absolute;
  left: 0;
  margin-left: 15px;
}
</style>
