<template>
  <div class="d-flex">
    <div
      class="header--item header--item"
      :class="item.key == activeItem ? 'header--item-active' : ''"
      v-for="item in $props.headerItems"
      :key="item.key"
      @click="() => onClickHeaderItem(item.key)"
    >
      <h4>
        {{ item.label }}
      </h4>
    </div>
  </div>
</template>

<script>
export default {
  props: ["headerItems", "activeHeaderItem"],
  mounted() {
    this.activeItem = this.$props.activeHeaderItem;
  },
  watch: {
    activeHeaderItem: {
      handler(newValue) {
        this.activeItem = newValue;
      },
    },
  },
  data() {
    return {
      activeItem: null,
    };
  },
  methods: {
    onClickHeaderItem(itemKey) {
      this.activeItem = itemKey;
      this.$emit("onClickHeaderItem", itemKey);
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  &--item {
    padding: 1.5rem 4rem;
    cursor: pointer;
    border-bottom: 2px solid var(--light-2);
    transition: all 0.2s ease-in-out;

    &:hover {
      transition: all 0.2s ease-in-out;
      border-color: transparent;
      border-bottom: 2px solid var(--light-green-0);
    }

    h4 {
      color: var(--grey-1);
      font-weight: bold;
    }
  }

  &--item-active {
    h4 {
      color: var(--dark-0);
      font-weight: bold;
    }
    border-bottom: 2px solid var(--secondary-green);
  }
}
</style>
