<template>
    <div>
    <TableVisualizationLayout :leftInfo="leftInfo" :leftAction="leftAction">
      <template slot="headerContent">
        <b-col class="pl-0 pr-0">
          <b-row class="mt-4 mb-4" align-h="between">
            <b-col class="pl-0 pr-0" sm="4">
              <b-form-input
                id="input-1"
                class="text-left"
                v-model="filterTitle"
                type="text"
                placeholder="Busque por nome do treinamento..."
              ></b-form-input>
            </b-col>
            <b-col class="pl-0 pr-0" sm="4">
              <b-select
                v-model="filterTypeId"
                :options="filterTypes"
              />
            </b-col>

          </b-row>

        </b-col>
      </template>     
      <template slot="table">
        <b-table
          class="table-custom spaced-text"
          :fields="fields"
          :items="items"
          v-show="!this.loading"
          show-empty
          empty-text="Nenhuma informação para exibir!"
        >
          <template #cell(report)="data">
            <div class="custom-row-table d-flex justify-content-center align-items-center">
              
              <div 
                :class="data.item.allowReport ? 'custom-icon-report-enable' : 'custom-icon-report-disable'" 
                class="mt-4 justify-content-center align-items-center custom-icon-report" 
                @click="onGenerateReport(data.item)" />
              
            </div>
          </template>
        </b-table>
        <div v-show="this.loading">
          <b-skeleton-table :rows="10" :columns="6"></b-skeleton-table>
        </div>
      </template>
      <template slot="footerContent">
        <b-pagination
          v-model="page"
          :total-rows="totalSize"
          :per-page="perPage"
          aria-controls="my-table"
          size="lg"
        />
      </template>
    </TableVisualizationLayout>
   
  </div>
</template>

<script>
import TableVisualizationLayout from "../../../layouts/TableVisualizationLayout.vue";
import {
  dateToLocaleDateString,
  dateToTime,
} from "../../../utils/date/date-utils";

import { getTrainings, getTrainingsReport } from '../../../services/training/trainingManagementService'
import { anchorBase64Download } from "../../../utils/anchor-base64-download/anchor-base64-download";
export default {
  components: {    
    TableVisualizationLayout,
  },
  mounted() {
    this.getTrainings();
  },
  watch: {
    page: {
      handler() {
        this.getTrainings();
      },
    },
    filterTypeId: {
      handler() {
        this.getTrainings();
      },
    },
    filterTitle: {
      handler() {
        if(this.filterTitle.length > 2 || this.filterTitle.length == 0) {
          this.getTrainings();
        }
      },
    },
  },
  data() {
    return {                  
      filterTypes: [{value : "RUNNING", text: "TREINAMENTOS VIGENTES"}, {value: "FINISHED", text: "TREINAMENTOS FINALIZADOS" }],
      filterTypeId: "RUNNING",
      filterTitle: "",
      page: 1,
      perPage: 10,
      totalSize: 10,
      leftAction: {
        text: "Gestão de treinamentos",
        onClick: this.leftActionClickHandler,
      },
      leftInfo:
        "Para acessar as informações dos treinamentos clique no botão editar.",
      fields: [
        {
          key: "title",
          label: "Nome",
        },
        {
          key: "publicTypes",
          label: "Público",
        },
        {
          key: "initialDate",
          label: "Data início",
        },
        {
          key: "endDate",
          label: "Data fim",
        },
        {
          key: "report",
          label: "Relatório", class: 'text-center'
        },
      ],
      items: [],
      loading: true,
    };
  },
  methods: {
    async onGenerateReport(item) {
      if(item.allowReport) {
        
        const reportResponse = await getTrainingsReport(item.id);      

        anchorBase64Download(null, 'application/xls', item.title + reportResponse.extension, reportResponse.fileBase64);
      }
    },
    async getTrainings() {
      try {
        this.loading = true;
        const trainingsResponse = await getTrainings(this.filterTypeId, this.filterTitle, this.page - 1, this.perPage);        

        this.items = trainingsResponse.items;
        this.totalSize = trainingsResponse.totalItems;

        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.error("getInformation", error);
      }
    },
    leftActionClickHandler() {
      this.$router.go(-1);
    },
    onFormatDate(value) {
      let valueSplit = value.split(" ");
      let horaSplit = valueSplit[1].split(":");
      return `${valueSplit[0]} às ${horaSplit[0]}h${horaSplit[1]}`;
    },
    dateToTime: dateToTime,
    dateToLocaleDate: dateToLocaleDateString,
  },
};
</script>

<style lang="scss" scoped>
.custom-icon-report {
  &-enable {
    background-image: url("../../../assets/icons/training-report-enable.svg");
     
    &:hover {
      cursor: pointer;
    }
  }

  &-disable {
    background-image: url("../../../assets/icons/training-report-disable.svg");
  }

  background-repeat: no-repeat;
  width: 22px;
  height: 36px;
}
</style>
