import { render, staticRenderFns } from "./BackbeatKnowMoreView.vue?vue&type=template&id=0250cde8&scoped=true&"
import script from "./BackbeatKnowMoreView.vue?vue&type=script&lang=js&"
export * from "./BackbeatKnowMoreView.vue?vue&type=script&lang=js&"
import style0 from "./BackbeatKnowMoreView.vue?vue&type=style&index=0&id=0250cde8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0250cde8",
  null
  
)

export default component.exports