import axios from "../http-client/axios";
import { GORIJA_URL } from "../urls/base-urls";
import {
  formatMoney,
} from "../../utils/format/formtUtils";

export async function getRequestHistory(
  crm,
  year
) {
  const resp = await axios.get(
    `${GORIJA_URL}/invoice-history/crm/${crm}/year/${year}`
  );
  let response = resp.data;
  
  return requestHistoryMapper(response.Notas)
}

function requestHistoryMapper(notas) {
  let requests = [];
  notas.forEach((nota) => {
    requests.push({
      mes: nota["competence"].month,
      valor: "R$ "+ formatMoney(nota["Valor"]),
      status: nota.Status
    });
  });
  return requests;
}
