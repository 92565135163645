// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../assets/images/ilus_analitica.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".margin[data-v-5841fd37]{margin-top:2.2rem}.span[data-v-5841fd37]{display:flex;width:100%;height:.15rem;border-radius:20%;background-color:var(--light-4)}.margin-analytics[data-v-5841fd37]{margin-right:9rem}.imagem-analytics[data-v-5841fd37]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-position:85% 90%}", ""]);
// Exports
module.exports = exports;
