<script>
import CustomCardInfo from "../components/custom-info-card/CustomInfoCard.vue";
import VisualizationTemplate from "../templates/VisualizationTemplate.vue";
import NavigationButton from "../components/navigation-button/NavigationButton.vue";

export const FormLayout = {
  props: ["leftInfo", "leftAction", "content", "showTable", "showLoading"],
  components: {
    VisualizationTemplate,
    CustomCardInfo,
    NavigationButton,
  },

  render() {
    const invalidHandler = (e) => {
      console.warn("Invalid handler!", e);
    };

    const leftAction = {
      text: "Invalid Text!",
      onClick: invalidHandler,
      ...this.$props.leftAction,
    };

    const leftInfo = this.$props.leftInfo;
    let showTable = this.$props.showTable;
    if (this.$props.showTable == undefined) {
      showTable = true;
    }

    let titleLoading = this.$props.titleLoading;
    if (this.$props.titleLoading == undefined) {
      titleLoading = true;
    }

    return (
      <VisualizationTemplate>
        <template slot="leftAction">
          <div class="title-custom">
            <NavigationButton
              actionClickHandler={leftAction.onClick}
              underLine={false}
            >
              <template slot="icon">
                <i class="fas fa-arrow-left"></i>
              </template>
              <template slot="text">{leftAction.text}</template>
            </NavigationButton>
            <div v-show={titleLoading}>{this.$slots.titleLoading}</div>
          </div>
        </template>

        <template slot="leftInfo">
          <p class="p4">{leftInfo}</p>
        </template>

        <template slot="headerContent">{this.$slots.headerContent}</template>
        <template slot="content">
          <b-card v-show={showTable} border-variant="light" body-class="p-0">
            {this.$slots.table}
          </b-card>
        </template>
        <template slot="footerContent">{this.$slots.footerContent}</template>
      </VisualizationTemplate>
    );
  },
};

export default FormLayout;
</script>
<style lang="scss">

.title-custom {
  display: grid;
  grid-template-columns: auto 232px;
  padding-right: 2rem;
}
</style>
