<template>
  <div>
    <div class="page-container">
      <div class="div-size">
        <div class="div-secundaria">
          <div class="page-col-1">
            <section class="page-header-new">
              <div class="custom-header">
                <div><h2 class="font-weight-bold">Quem somos</h2></div>
              </div>
            </section>
            <div class="span-new"></div>
            <div class="config-content-new">
              <p class="p2 config-content-new--p1">
                A Unimed Grande Florianópolis é uma cooperativa de trabalho
                médico que oferta serviços e planos de saúde a 17 cidades da
                região litorânea de Santa Catarina.
              </p>
              <p class="p2 config-content-new--p2">
                Estamos presentes na vida de mais de 220 mil pessoas, que
                desfrutam de assistência médica completa, sustentável e de
                qualidade.
              </p>
              <p class="p2 config-content-new--p3">
                Fundada em 30 de agosto de 1971, nossa marca é referência de
                excelência na saúde suplementar, nossas conquistas são resultado
                da dedicação e empenho dos mais de 1.700 cooperados e dos
                serviços fornecidos por nossos parceiros credenciados.
              </p>
              <p class="p2">
                Disponibilizamos aos nossos clientes e parceiros uma estrutura
                de serviços próprios de qualidade, composta por: hospital,
                centro de diagnóstico, serviços de imagem, pronto atendimentos
                adulto e infantil, além de serviços virtuais como o pronto
                atendimento digital e a telemedicina.
              </p>
              <p class="p2 font-weight-bold config-content-new--p4">
                A Unimed Grande Florianópolis em números:
              </p>
            </div>
            <div class="imagem-institutional-page">
              <img src="../../../assets/images/unimed_numeros.svg" />
            </div>

            <div class="organizational-mission padding-config-one">
              <div
                class="col-custom organizational-mission--card organizational-mission--card--p1"
              >
                <h2 class="font-weight-bold">Missão</h2>

                <div class="organizational-mission--card--span">
                  <div></div>
                </div>

                <p class="p3">
                  Promover saúde e qualidade de vida, buscando a satisfação dos
                  clientes, cooperados, colaboradores e demais profissionais,
                  com responsabilidade socioambiental.
                </p>
              </div>
              <div
                class="col-custom organizational-mission--card organizational-mission--card--p2"
              >
                <h2 class="font-weight-bold">Visão</h2>
                <div class="organizational-mission--card--span">
                  <div></div>
                </div>
                <p class="p3">
                  Ser reconhecida como cooperativa de excelência e a melhor
                  promotora de saúde e qualidade de vida.
                </p>
              </div>
            </div>
            <div class="organizational-mission">
              <div
                class="col-custom organizational-mission--card organizational-mission--card--p3"
              >
                <h2 class="font-weight-bold">Valores</h2>
                <div
                  class="font-weight-bold organizational-mission--card--span"
                >
                  <div></div>
                </div>
                <p class="p3">
                  Os valores da unimed que constituem seus princípios são:
                  eqüidade, ética, integridade, lealdade, solidariedade, verdade
                  e eficiência.
                </p>
              </div>
              <div
                class="col-custom organizational-mission--card organizational-mission--card--p4"
              >
                <h2 class="font-weight-bold">Politica de qualidade</h2>
                <div class="organizational-mission--card--span">
                  <div></div>
                </div>
                <p class="p3">
                  Buscar a satisfação dos clientes através da melhoria contínua
                  dos processos, oferecendo e administrando planos de saúde e
                  serviços com qualidade.
                </p>
              </div>
            </div>
            <div
              @click="onClickHandler('TRAINING')"
              class="div-training align-items-center"
            >
              <div class="d-flex justify-content-center">
                <img
                  class="img-training" 
                  v-show="true"
                  src="@/assets/icons/MDI-lightbulb-on-outline.svg"
                />
              </div>
              <div>
                <p class="text-custom-training">Treinamentos</p>
                <p class="text-custom-training-info">Aqui oferecemos uma ampla variedade de cursos e treinamentos abordando temas diversos</p>
              </div>
              <img
                v-show="true"
                class="d-flex justify-content-center"
                src="@/assets/images/mdi-chevron-right.svg"
              />
            </div>
          </div>
          <InstitutionalPageModal />
          <div class="page-col-2">
            <section class="page-header-director">
              <div class="custom-header">
                <div><h2 class="font-weight-bold">Diretoria</h2></div>
                <div>
                  <div
                    class="custom-button-chart"
                    v-b-modal:institutional-page-show-modal
                  >
                    <img src="@/assets/icons/MDI-family-tree.svg" />
                    <div class="custom-text-chart">Organograma</div>
                  </div>
                </div>
              </div>
            </section>
            <div class="span-new"></div>
            <div class="direction-image">
              <div class="direction-image--center">
                <div>
                  <img
                    class="direction-image--president"
                    src="../../../assets/images/direction/Presidente.png"
                  />
                </div>
                <div>
                  <img
                    class="direction-image--vice-president"
                    src="../../../assets/images/direction/VicePresidente.png"
                  />
                </div>
                <div>
                  <img
                    class="direction-image--superintendente"
                    src="../../../assets/images/direction/Superintendente.png"
                  />
                </div>
              </div>
            </div>
            <div class="p2 links upperCase">
              <div
                @click="onClickHandler('OUR_STORY')"
                v-show="!isMobile()"
                class="links--internal"
              >
                Nossa história
                <img
                  v-show="true"
                  class="mdi-chevron-right"
                  src="@/assets/images/mdi-chevron-right.svg"
                />
              </div>
              <div
                @click="onClickHandler('COLLECTIONS')"
                v-show="!isMobile()"
                class="links--internal"
              >
                Reconhecimentos
                <img
                  v-show="true"
                  class="mdi-chevron-right"
                  src="@/assets/images/mdi-chevron-right.svg"
                />
              </div>
              <div
                @click="onClickHandler('TRANSPARENCY_AND_COMMITMENT')"
                v-show="!isMobile()"
                class="links--internal"
              >
                Transparência e Comprometimento
                <img
                  v-show="true"
                  class="mdi-chevron-right"
                  src="@/assets/images/mdi-chevron-right.svg"
                />
              </div>
              <div
                @click="onClickHandler('OUR_WAY_OF_CARE')"
                v-show="!isMobile()"
                class="links--internal"
              >
                Nosso jeito de cuidar
                <img
                  v-show="true"
                  class="mdi-chevron-right"
                  src="@/assets/images/mdi-chevron-right.svg"
                />
              </div>
              <div
                @click="onClickHandler('DOCUMENT_AND_MINUTES_OF_UGF')"
                class="links--internal"
              >
                Documentos e atas da UGF
                <img
                  v-show="true"
                  class="mdi-chevron-right"
                  src="@/assets/images/mdi-chevron-right.svg"
                />
              </div>
              <div
                @click="onClickHandler('ECONOMIC_PANEL')"
                class="links--internal"
              >
                Painel econômico
                <img
                  v-show="true"
                  class="mdi-chevron-right"
                  src="@/assets/images/mdi-chevron-right.svg"
                />
              </div>
              <div
                @click="onClickHandler('ANT_DUTS')"
                v-show="true"
                class="links--internal"
              >
                ROL DE COBERTURA ANS + DUTS
                <img
                  v-show="true"
                  class="mdi-chevron-right"
                  src="@/assets/images/mdi-chevron-right.svg"
                />
              </div>
              <div
                @click="onClickHandler('NEW_TECNOLOGY')"
                v-show="true"
                class="links--internal"
              >
                NOVAS TECNOLOGIAS
                <img
                  v-show="true"
                  class="mdi-chevron-right"
                  src="@/assets/images/mdi-chevron-right.svg"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RoutesPaths from "../../../router/router-structure/routes-paths";
import InstitutionalPageLinkType from "../../../services/institutional-page/InstitutionalPageLinkType";
import { isMobile } from "../../../utils/mobile/mobile";
import LinkType from "../../../static-data/Links.js";
import InstitutionalPageModal from "./modal/InstitutionalPageModal.vue";

export default {
  components: { InstitutionalPageModal },
  async mounted() {
    this.openLink = this.$route.query.openLink;

    if (this.openLink === "true") {
      this.$bvModal.show("institutional-page-show-modal");
    }
  },
  methods: {
    isMobile: isMobile,
    onClickHandler(linkType) {
      const typeLink = InstitutionalPageLinkType[linkType];
      switch (typeLink) {
        case InstitutionalPageLinkType.COLLECTIONS:
          this.onWindowsOpen(
            "https://www.unimed.coop.br/site/web/florianopolis/reconhecimentos"
          );
          break;
        case InstitutionalPageLinkType.DOCUMENT_AND_MINUTES_OF_UGF:
          this.$router.push(RoutesPaths.documentsAtasPage.documentsAtas());
          break;
        case InstitutionalPageLinkType.ECONOMIC_PANEL:
          this.$router.push(RoutesPaths.institutionalPage.panelEconomic());
          break;
        case InstitutionalPageLinkType.OUR_STORY:
          this.onWindowsOpen(
            "https://www.unimed.coop.br/site/web/florianopolis/historico"
          );
          break;
        case InstitutionalPageLinkType.OUR_WAY_OF_CARE:
          this.onWindowsOpen(
            "https://www.unimed.coop.br/site/web/florianopolis/jeitodecuidar"
          );
          break;
        case InstitutionalPageLinkType.TRANSPARENCY_AND_COMMITMENT:
          this.onWindowsOpen(
            "https://www.unimed.coop.br/site/web/florianopolis/transparencia"
          );
          break;
        case InstitutionalPageLinkType.TRAINING:
          this.$router.push(RoutesPaths.trainingPage.rootName());
          break;
        case InstitutionalPageLinkType.ANT_DUTS:
          this.onWindowsOpen(LinkType.ANT_DUTS);
          break;
        case InstitutionalPageLinkType.NEW_TECNOLOGY:
          this.onWindowsOpen(LinkType.NEW_TECNOLOGY);
          break;
      }
    },
    onWindowsOpen(link) {
      window.open(link, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.page-container {
  width: 100%;
  display: flex !important;
  justify-content: center !important;
}

.div-secundaria {
  display: flex !important;
  justify-content: center !important;
  width: 117rem;
  max-width: 117rem;

  @media screen and (max-width: 1056px) {
    flex-direction: column;
  }
}

.div-size {
  display: flex !important;
  justify-content: center !important;
  width: 90%;
}

.page-col-1 {
  width: 100%;
  padding-right: 8%;

  @media screen and (max-width: 1056px) {
    padding-right: 0%;
  }
}

.page-col-2 {
  width: 42rem;
  min-width: 42rem;
  margin-top: 0px;

  @media screen and (max-width: 1056px) {
    width: 100%;
    min-width: 0rem;
    margin-top: 4rem;
  }
}

.page-header-new {
  text-align: left;
  margin-bottom: 1.5rem;
}

.page-header-director {
  margin-bottom: 0.7rem;
}

.span-new {
  width: 100%;
  height: 0.15rem;
  border-radius: 20%;
  background-color: var(--secondary-green);
}

.config-content-new {
  width: 100%;
  &--p1 {
    margin-top: 3.3rem;
  }
  &--p2 {
    margin-top: 1rem;
  }
  &--p3 {
    margin-top: 1rem;
  }
  &--p4 {
    margin-top: 2rem;
  }
}

.imagem-institutional-page {
  height: 11.01rem;
  width: 100%;
  border-radius: 2rem;
  border: none;
  margin-top: 0.8rem;

  img {
    width: 100%;
  }
}

.padding-config-one {
  padding-top: 5.4rem;

  @media screen and (max-width: 526px) {
    padding-top: 0.5rem;
  }
}

.padding-config-two {
  padding-top: 5.4rem;
}

.col-custom {
  flex-basis: 0;
  flex-grow: 1;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  max-width: 100%;

  @media screen and (max-width: 526px) {
    width: 24rem !important;
    max-width: 24rem !important;
    position: relative;
    flex-grow: 0;
    flex-basis: auto;
  }
}

.organizational-mission {
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  @media screen and (max-width: 526px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &--card {
    height: 20.3rem;
    background-color: #ffffff;
    border-radius: 2rem;
    width: 24rem;

    @media screen and (max-width: 526px) {
      height: 20.3rem;
      width: 24rem;
    }

    h2 {
      text-align: center;
      padding-top: 3rem;
    }

    p {
      text-align: left;
    }

    &--span {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      height: 0.15rem;
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;

      div {
        height: 0.15rem;
        width: 3rem;
        border-radius: 20%;
        background-color: var(--secondary-green);
      }
    }

    &--p1 {
      margin-bottom: 1rem;
      margin-right: 1rem;

      @media screen and (max-width: 526px) {
        margin-bottom: 0rem;
        margin-right: 0rem;
        margin-left: 0rem;
      }
    }

    &--p2 {
      margin-bottom: 1rem;
      margin-left: 1rem;

      @media screen and (max-width: 526px) {
        margin-top: 2rem;
        margin-bottom: 0rem;
        margin-right: 0rem;
        margin-left: 0rem;
      }
    }

    &--p3 {
      margin-top: 1rem;
      margin-right: 1rem;

      @media screen and (max-width: 526px) {
        margin-top: 2rem;
        margin-bottom: 0rem;
        margin-right: 0rem;
        margin-left: 0rem;
      }
    }

    &--p4 {
      margin-top: 1rem;
      margin-left: 1rem;

      @media screen and (max-width: 526px) {
        margin-top: 2rem;
        margin-bottom: 0rem;
        margin-right: 0rem;
        margin-left: 0rem;
      }
    }

    &----division {
      height: 0px;
    }
  }
}

.direction-image {
  width: 100%;
  display: flex !important;
  justify-content: center !important;

  &--center {
    display: flex !important;
    justify-content: center !important;
    flex-direction: column;
  }

  &--president {
    margin-top: 1.5rem;
    height: 13.8rem;
  }

  &--vice-president {
    height: 13.7rem;
  }

  &--superintendente {
    height: 13.8rem;
  }

  img {
    width: 100%;
  }
}

.links {
  margin-top: 5.2rem;
  width: 100%;

  &--internal {
    display: flex;
    align-items: center;
    padding: 0 1rem 0 2rem;
    cursor: pointer;
    border-radius: 1rem;

    background-color: white;
    color: var(--primary);
    width: 100%;
    text-align: left;
    padding-left: 1.4rem;
    height: 5rem;
    margin-bottom: 2rem;

    img {
      margin-left: auto;
    }
  }
}

.custom-header {
  width: 100%;
  display: grid;
  border-style: none;
  grid-template-columns: auto 16rem;
}

.custom-button-chart {
  display: grid;
  grid-template-columns: 2.4rem auto;
  align-items: center;
  width: 16rem;
  height: 3.8rem;
  border-radius: 0.5rem;
  border: 1px solid #00995d;
  opacity: 1;
  padding-left: 1rem;
  cursor: pointer;
}

.custom-text-chart {
  color: var(--primary);
  font-size: 1.6rem;
  opacity: 1;
  padding-left: 1rem;
}

.div-training {
  cursor: pointer;
  border-radius: 1rem;
  height: 7.5rem;
  background-color: white;
  width: 100%;
  display: grid;
  grid-template-columns: 8rem auto 4rem;
  margin-top: 4.4rem;
}

.text-custom-training {
  color: var(--dark-0);
  font-size: multiplier-default-font-size(3); // 16
}

.text-custom-training-info {
  font-size: 1.1rem;
  color: var(--grey-2);
}

.img-training {
  height: 3.4rem;
  width: 3.4rem;
}

.links--internal:hover {
  border: 0.1rem solid #0a5f55 !important;
}

.div-training:hover {
  border: 0.1rem solid #0a5f55 !important;
}
</style>
