<script>

export const FormLayoutCustom = {
  props: [],
  components: {
  },

  render() {
    return (
      <b-container>
        <b-container class="cards">
          <b-container>
            <div class="card-one">
              {this.$slots.typeDocument}
            </div>
          </b-container>

          <b-container></b-container>

          <b-container class="card-two">
            <div class="card-two">
              {this.$slots.yearCompetence} 
            </div>
          </b-container>

          <b-container></b-container>

          <b-container class="card-tree">
            <div class="card-tree">
              {this.$slots.buttonSearch}
            </div>
          </b-container>
        </b-container>
      </b-container>
    );
  },
};

export default FormLayoutCustom;
</script>

<style lang="scss" scoped>

.cards {
  max-width: 100%;
  display: grid;
  border-style: none;
  grid-column: 0;
}

.card-one {
  padding-bottom: 1rem;
}

.card-two {
  padding-bottom: 2rem;
}

@media (min-width: 900px) {
  .cards {
    grid-template-columns: 57% 3% 27% 3% 10%;
  }

  .card-one, .card-two {
    padding-bottom: 0rem;
  }
}

</style>
