<template>
  <div class="login-container img-login-illustration">
    <div
      :class="isMobile() ? 'login-mobile-container' : ''"
      class="login-card-container"
    >
      <div class="login-wecolme">
        <h2>Boas vindas ao novo Portal do Cooperado!</h2>
        <div class="separator-line"></div>

        <p class="p2 mt-3 custom-one">
          A UGF preparou um espaço exclusivo para você médico cooperado.
        </p>
        <p class="p2 mt-3 custom-two">
          Atenção! Se esse é o seu primeiro acesso ao novo portal, você deve
          redefinir sua senha
          <b-link @click="urlPassordForgot()">clicando aqui</b-link>
        </p>
      </div>
      <div class="mt-5">
        <b-form @submit="this.onSubmit">
          <b-card class="login-card card-border--1">
            <b-card-body>
              <div>
                <div class="mb-4 ml-2">
                  <b-link @click="downloadFirstAccessHelper()">
                    <img
                      class="mb-1 icon-help-circle"
                      src="@/assets/icons/help-circle.svg"
                    />
                    Como fazer o primeiro acesso
                  </b-link>
                </div>
                <b-form-group label="CRM" label-for="crmLogin">
                  <b-form-input
                    placeHolder="Informe seu CRM"
                    id="crmLogin"
                    type="text"
                    required
                    v-model="user.login"
                  />
                </b-form-group>
                <b-form-group label="Senha" label-for="passwordLogin">
                  <i
                    class="fas fa-eye"
                    v-show="showPassword"
                    @click="toogleShowPassword()"
                  ></i>
                  <i
                    class="fas fa-eye-slash"
                    v-show="!showPassword"
                    @click="toogleShowPassword()"
                  ></i>
                  <b-form-input
                    placeHolder="Informe sua senha"
                    id="passwordLogin"
                    :type="getInputType()"
                    required
                    v-model="user.password"
                  />
                </b-form-group>
                <div class="mt-4">
                  <b-link @click="urlPassordForgot()">Redefinir senha</b-link>
                </div>
                <div>
                  <b-button
                    type="submit"
                    variant="primary"
                    id="entrar"
                    class="button"
                    >Entrar
                    <i
                      class="fas fa-spinner fa-spin icon"
                      v-show="this.loading"
                    ></i
                  ></b-button>
                </div>
                <div
                  class="invalid"
                  v-show="this.msgErroInvalid != null && !this.loading"
                >
                  <i class="fas fa-exclamation-circle invalid"></i>
                  {{ this.msgErroInvalid }}
                </div>
              </div>
            </b-card-body>
          </b-card>
        </b-form>
        <div>         
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "@vue/composition-api";
import RoutesPaths from "../../../router/router-structure/routes-paths";
import VerifyUserTermError from "../../../errors/VerifyUserTermError";
import { isMobile } from "../../../utils/mobile/mobile";
import { getGeneralInformation } from "../../../services/general-information/general-information";

const FILE_NAME = "Primeiro acesso portal do cooperado - passo a passo final";
const URL_FILE =
  "https://firebasestorage.googleapis.com/v0/b/ugf-hm-plataforma-cooperado/o/first-access%2FPrimeiro%20acesso%20portal%20do%20cooperado%20-%20passo%20a%20passo%20final%20.pdf?alt=media&token=dfca9b23-7cd7-4d25-ba47-5266a293d064";

export default {
  setup() {
    const user = ref({ login: null, password: null });
    return { user };
  },
  data() {
    return {
      showPassword: false,
      loading: false,
      msgErroInvalid: null,
      acceptedTermUse: false,      
    };
  },
  async created() {
    await this.$store.dispatch("logout");
    if (this.$route.query.isApp) {
      this.$store.dispatch("isApp", this.$route.query.isApp === "true");
    } else {
      this.$store.dispatch("isApp", false);
    }
    this.$router.replace({ query: { param: [] } });
  },
  methods: {
    toogleShowPassword() {
      this.showPassword = !this.showPassword;
    },
    getInputType() {
      return this.showPassword ? "text" : "password";
    },
    isMobile: isMobile,
    async onSubmit(e) {
      e.preventDefault();
      this.doLogin();
    },
    async doLogin() {
      try {
        this.loading = true;
        this.setDisabled(true);        
        const response = await this.$store.dispatch("requestLogin", this.user);
              
        await this.$store.dispatch("initializePermissions", response);
        
        await this.$store.dispatch("setRoutesDynamic");
        

        if (this.$store.getters.isAdmin) {
          this.$router.push({ path: RoutesPaths.contentManagement.rootName() });
        } else {
          
          await this.$store.dispatch("verifyUseTerm");
          
          await this.$store.dispatch("verifyStatus");
         
          this.doGetUserInformations();
          
          this.doGetAnalyticalData();

          this.$router.push({ path: RoutesPaths.home.rootName() });
        }

        this.doGetNewness();

        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.error("doLogin", error);
        this.setDisabled(false);
        if (error instanceof VerifyUserTermError) {
          await this.$store.dispatch("logout");
          return;
        }
        if (
          error.response &&
          error.response.status == 400 &&
          error.response.data &&
          error.response.data.message == "Bad credentials"
        ) {
          this.msgErroInvalid = "CRM e/ou senha inválido(s)";
        }
      }
    },
    async doGetUserInformations() {
      try {
        const crm = this.$store.getters.getUserName;

        if (!crm) return;
        this.getUserInfo(crm);
        await this.$store.dispatch("requestUserInformation", crm);
      } catch (error) {
        console.error("doGetUserInformations", error);
      }
    },
    setDisabled(value) {
      document.getElementById("crmLogin").disabled = value;
      document.getElementById("passwordLogin").disabled = value;
      document.getElementById("entrar").disabled = value;
    },
    urlPassordForgot() {
      this.$router.push("/password-recovery");
    },
    async doGetAnalyticalData() {
      try {
        const crm = this.$store.getters.getUserName;

        if (!crm) return;

        this.$store.dispatch("requestAnalyticalData", crm);
      } catch (error) {
        console.error("requestAnalyticalData", error);
      }
    },
    downloadFirstAccessHelper() {
      var element = document.createElement("a");
      if (this.$store.getters.isApp) {
        element.setAttribute("href", `${URL_FILE.replace("https", "http")}`);
      } else {
        element.setAttribute("href", `${URL_FILE}`);
      }
      element.setAttribute("download", FILE_NAME);
      element.setAttribute("target", "_blank");
      document.body.appendChild(element);
      element.click();
    },
    getUserInfo(user) {
      
      getGeneralInformation(user)
        .then((userInformation) => {
          userInformation.nickname = userInformation.name
            ? userInformation.name.split(" ", 1)[0]
            : "Cooperado";
          localStorage.setItem(
            "user-information",
            JSON.stringify(userInformation)
          );
        })
        .catch((err) => {
          console.error("requestLogin error: ", err);          
        });
    },
    async doGetNewness() {
      const crm = this.$store.getters.getUserName;
      try {

        this.$store.dispatch("requestNewnessData", crm);
        this.errors.push({
          name: "doGetNewness - requestNewnessData",
          value: crm,
        });
      } catch (error) {
        console.error("requestNewnessData", error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
i.fa-eye,
i.fa-eye-slash {
  position: absolute;
  margin-left: 263px;
  margin-top: 10px;
  &:hover {
    cursor: pointer;
  }
}
.login-card-container {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  width: 55rem;
}

.login-card {
  width: 44rem;
  height: 41rem;
  padding: 4rem 5rem;
  @include header5;
}

.img-login-illustration {
  background-image: "";

  @media (min-width: 992px) {
    background-image: url("../../../assets/images/login/login-illustration.svg");
  }

  @media (min-height: 882px) {
    background-image: url("../../../assets/images/login/login-illustration.svg");
  }
}

.login-container {
  display: flex;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;

  @include media-breakpoint-up(xs) {
    justify-content: center;
    background-position: right bottom;
    background-size: 90% 28%;
  }

  @include media-breakpoint-up(sm) {
    justify-content: center;
    background-position: right bottom;
    background-size: 90% 30%;
  }

  @include media-breakpoint-up(md) {
    justify-content: center;
    background-position: right bottom;
    background-size: 90% 30%;
  }

  @include media-breakpoint-up(lg) {
    justify-content: initial;
    background-position: right top;
    background-size: 50% 100%;
  }

  @include media-breakpoint-up(xl) {
    justify-content: initial;
    background-position: right top;
    background-size: 60% 100%;
  }
}

.login-wecolme {
  max-width: 43rem;
  text-align: center;
}
.icon {
  font-size: 2rem;
}

a {
  font-size: 1.4rem;
}

.button {
  margin-top: 3rem;
  width: 9rem;
  height: 4rem;
}
.invalid {
  color: #dc3545;
  margin-top: 0.8rem;
}

.icon-help-circle {
  height: 2rem;
}
.login-mobile-container {
  width: 38rem;
}

.custom-one {
  color: #2a5d56;
}
input {
  text-align: left;
}
.custom-two {
  color: #2a5d56;
  font-weight: bold;
  padding-left: 1rem;
  padding-right: 1rem;
}
</style>
