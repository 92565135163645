<script>

export const FormLayoutCustomGeneric = {
  props: [],
  components: {
  },

  render() {
    return (
      <b-container>
        <b-container class="cards-custom-generic-atas">
          <b-container class="d-flex align-items-center">
              {this.$slots.one}
          </b-container>

          <b-container class="">
              {this.$slots.two} 
          </b-container>
        </b-container>
      </b-container>
    );
  },
};

export default FormLayoutCustomGeneric;
</script>

<style lang="scss" scoped>

.cards-custom-generic-atas {
  max-width: 100%;
  display: grid;
  border-style: none;
  grid-column: 0;
}

@media (min-width: 900px) {
  .cards-custom-generic-atas {
    grid-template-columns: 135px auto;
  }
}
</style>
